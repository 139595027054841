:root {
  --foreground-rgb: rgba(20, 20, 20, 1);
  --background-start-rgb: rgba(20, 20, 20, 1);
  --background-end-rgb: rgba(20, 20, 20, 1);
  // back ground color
  --app-home-bg-rgb: #000;
  --app-line-bg-rgb: rgba(242, 242, 242, 0.3);
  --app-line-color: rgba(242, 242, 242, 0.3);
  --app-input-bg-rgb: #333;
  --app-home-gpt-bg-rgb: rgba(208, 58, 58, 0.1);
  --app-home-gpt-a-rgb: rgba(208, 58, 58, 1); //#E1AE72
  --app-share-bg-rgb: #333;
  // title color
  --app-text-color: rgb(255, 255, 255);
  --app-text-60-color: rgba(255, 255, 255, 0.6);
  --app-text-30-color: rgba(255, 255, 255, 0.3);

  //
  --other-text-color: rgba(20, 20, 20, 1);
  --other-bg-color: #fff;

  // link
  --app-link-color: #369eff;

  --app-primary-color: #9873ff;

  //
  .next-antd-pagination .next-antd-pagination-item a {
    display: block;
    padding: 0 6px;
    color: white;
  }

  .next-antd-rate-star-second {
    color: #666;
  }

  .linearSelect1 {
    background: linear-gradient(160deg, #725cff, rgb(194, 208, 251));
    border-radius: 12px;
    border: 0px;
  }

  .next-antd-float-btn-default .next-antd-float-btn-body {
    background-color: var(--app-primary-color);
  }
  .next-antd-float-btn-default
    .next-antd-float-btn-body
    .next-antd-float-btn-content
    .next-antd-float-btn-description {
    color: #fff;
  }

  .next-antd-modal-content {
    padding: 0px !important;
  }
}


.mainContainer {
  max-width: 400px;
  border-radius: 24px;

  :global {
    .next-antd-modal-footer {
      display: none;
    }
  }

  .homeContent {
    padding: 1rem 2rem 0;

    .inputItem {
      height: 44px;
      background: #f2f2f2;

      :global {
        .next-antd-input {
          background: #f2f2f2 !important;
        }
      }
    }

    .agreeDesc {
      font-size: 0.875rem;
      margin-bottom: 20px;

      span {
        color: var(--app-text-color);
      }

      .link {
        color: var(--app-primary-color);
      }
    }

    .staySignedIn {
      font-size: 0.875rem;
      color: var(--app-primary-color);
    }

    .login-form {
      font-size: 0.875rem;
    }
    .forgotItem {
      // float: right;
      display: block;
      width: 100%;
      text-align: center;
      font-size: 0.875rem;
      color: var(--app-primary-color);
    }
    .ant-col-rtl .login-form-forgot {
      float: left;
    }
    .loginFormButton {
      width: 100%;
      height: 44px;
      font-weight: bold;
    }

    .createFormButton {
      width: 100%;
      height: 44px;
      background: white;
      color: var(--app-primary-color);
      border: 1px solid var(--app-primary-color);
      font-weight: bold;
    }

    .googleLoginText {
      margin-top: 0;
      text-align: center;
      margin-bottom: 20px;

      span {
        color: var(--app-text-color);
        font-size: 1rem;
        font-weight: bold;
      }
      span:last-child {
        font-weight: normal;
      }
    }

    .googleFormButton {
      width: 100%;
      height: 44px;
      background: white;
      color: var(--app-text-color);
      border: 1px solid var(--app-text-color);
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        color: var(--app-text-color);
        font-size: 1rem;
        padding-left: 5px;
      }

      span:last-child {
        font-weight: bold;
      }
    }

    .accountContainer {
      text-align: center;
      margin-top: 20px;
      font-size: 14px;

      span:last-child {
        color: var(--app-primary-color);
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 440px) {
  .mainContainer {
    max-width: calc(100% - 2rem);
    border-radius: 24px;

    .homeContent {
      padding: 0.5rem 1rem 0;

      .inputItem {
        height: 40px;
      }

      .agreeDesc {
        margin-bottom: 15px;
      }

      .loginFormButton {
        height: 40px;
      }

      .createFormButton {
        height: 40px;
      }

      .googleLoginText {
        margin-bottom: 15px;

        span {
          font-size: 0.875rem;
        }
      }

      .googleFormButton {
        height: 40px;
        span {
          font-size: 0.875rem;
        }
      }

      .accountContainer {
        margin-top: 15px;
      }
    }
  }
}


@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins-Regular.otf');
  font-weight: regular;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins-Medium.otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins-Bold.otf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'PlayfairDisplay';
  src: url('/fonts/PlayfairDisplay-Regular.ttf');
  font-weight: regular;
  font-style: normal;
}

@font-face {
  font-family: 'PlayfairDisplay-Medium';
  src: url('/fonts/PlayfairDisplay-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'PlayfairDisplay-Bold';
  src: url('/fonts/PlayfairDisplay-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'PlayfairDisplay-MediumItalic';
  src: url('/fonts/PlayfairDisplay-MediumItalic.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'PlayfairDisplay-Italic';
  src: url('/fonts/PlayfairDisplay-Italic.ttf');
  font-weight: regular;
  font-style: normal;
}

@font-face {
  font-family: 'PlayfairDisplay-BoldItalic';
  src: url('/fonts/PlayfairDisplay-BoldItalic.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'PlayfairDisplay-SemiBoldItalic';
  src: url('/fonts/PlayfairDisplay-SemiBoldItalic.ttf');
  font-weight: regular;
  font-style: normal;
}

@font-face {
  font-family: 'PlayfairDisplay-Black';
  src: url('/fonts/PlayfairDisplay-Black.ttf');
  font-weight: regular;
  font-style: normal;
}

@font-face {
  font-family: 'PlayfairDisplay-BlackItalic';
  src: url('/fonts/PlayfairDisplay-BlackItalic.ttf');
  font-weight: regular;
  font-style: normal;
}

@font-face {
  font-family: 'PlayfairDisplay-ExtraBoldItalic';
  src: url('/fonts/PlayfairDisplay-ExtraBoldItalic.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'PlayfairDisplay-SemiBold';
  src: url('/fonts/PlayfairDisplay-SemiBold.ttf');
  font-weight: semibold;
  font-style: normal;
}

@font-face {
  font-family: 'PlayfairDisplay-ExtraBold';
  src: url('/fonts/PlayfairDisplay-ExtraBold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'CrimsonText-Italic';
  src: url('/fonts/CrimsonText-Italic.ttf');
  font-style: normal;
}
